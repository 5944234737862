#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.logo img {
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 5px;
  padding-right: 10px;
  width: 100%;
  border-bottom: 5px solid #39c463;
  height: 70%;
}
.site-layout .site-layout-background {
  margin: 10px;
  margin-bottom: 10px;
  background: #000;
}
.charts {
  position: flex;
  flex-direction: row;
}
.table {
  align-items: center;
  height: 100%;
  padding: 20px 10px 10px 10px;
}
.logout {
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding-left: 0;
  outline: none;
}
