#notfound {
  position: relative;
  height: 100vh;
  background-color: #1f1f1f;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}
.notfound .notfound-404 {
  height: 158px;
  line-height: 153px;
}
.notfound .notfound-404 h1 {
  color: #1f1f1f;
  font-size: 220px;
  letter-spacing: 10px;
  margin: 0;
  font-weight: 700;
  text-shadow: 2px 2px 0 #c9c9c9, -2px -2px 0 #c9c9c9;
}
.notfound .notfound-404 h1 > span {
  text-shadow: 2px 2px 0 #39c463, -2px -2px 0 #39c463, 0 0 8px #36c463;
}
.notfound p {
  color: #c9c9c9;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 15px;
}
.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: 0 0;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound a:hover {
  color: #39c463;
  border-color: #39c463;
}
@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 122px;
    line-height: 122px;
  }
  .notfound .notfound-404 h1 {
    font-size: 122px;
  }
}
