div.ant-row.content {
  height: 100%;
}
.home {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.firstView {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 42px;
}
.firstView span {
  margin-bottom: 5%;
}
.welcome {
  width: 20rem;
}
