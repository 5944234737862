div.results,
div.surveyStatus,
div.performance {
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px 0 0 0;
  height: 100%;
}
.modifySurvey {
  width: 65%;
  align-self: center;
}
.surveyButtons{
  display: flex;
  flex-flow: row wrap;
}
.ant-space-align-center {
  justify-content: space-evenly;
  align-items: center;
}
.colorCode {
  display: flex;
  margin-top: 1%;
  width: 100%;
  height: 60px;
  justify-content: space-around;
}
.anticon-fire {
  padding-right: 10px;
  font-size: 24px;
}
.ant-dropdown {
  background-color: black;
  height: 240x;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #1f1f1f;
  border-radius: 12px;
}
.fireball {
  padding-left: 35%;
}
.perfectMatch {
  color: #4dad45;
}
.strongMatch {
  color: #82d0ca;
}
.goodMatch {
  color: #f2f234;
}
.willing {
  color: #ffa502;
}
.notMatch {
  color: #ff5938;
}
.pending {
  color: white;
}
.ant-table-wrapper {
  margin: 30px;
}
