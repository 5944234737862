.modifySurvey {
  margin-top: 1.5%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.sendButton:hover .sendButton:active {
  background: #257f40;
}
.surveyButtons {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  width: 91%;
  margin-bottom: -2.5%;
  justify-content: space-evenly;
}
.surveyButtons span {
  margin-top: 1%;
}
div.data{
  text-align: center;
  display: block;
  margin: auto;
  border-radius: 5%;
}
div.ant-table-column-sorters {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.ant-table-cell {
  text-align: center;
}

.anticon.incomplete {
  color: #ff5938;
}
.anticon.complete {
  color: #4dad45;
}
.good {
  background-color: #4dad45;
  width: 50%;
}
.poor {
  background-color: #ff5938;
  width: 50%;
}
