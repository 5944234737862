span {
  color: #f5f5f5;
}

.boxupload {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 15%;
}

.downloadbutton {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
}

.buttonSubmit {
  display: flex;
  justify-content: center;
}

#buttonUpload {
  position: relative;
  margin-top: 30px;
  background-color: #39c463;
  border: none;
  font-size: 14px;
  color: #ffffff;
  width: 100px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

#buttonUpload:after {
  content: '';
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

#buttonUpload:active:after {
  background: #39c463;
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
