.editMentors {
  padding: 50px 50px 50px 50px;
}

#boxDeleteMentors {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-self: flex-start;
  border-top: 3px solid #39c463;
  border-bottom: 3px solid #39c463;
  border-right: 3px solid #39c463;
  border-left: 3px solid #39c463;
  height: 400px;
  padding: 20px 20px 20px 20px;
}

#boxAddMentors {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  border-top: 3px solid #39c463;
  border-bottom: 3px solid #39c463;
  border-right: 3px solid #39c463;
  border-left: 3px solid #39c463;
  height: 400px;
  padding: 20px 20px 20px 20px;
}
