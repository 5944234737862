.modalBox {
  display: block;
  text-align: center;
  width: 440px;
  margin: 0 auto;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.664);
}
.modalBox span {
  text-align: justify;
  text-justify: inter-cluster;
  color: #ffffff77;
}

div.modalBox p{
  font-weight: 400;
}
div.ant-modal-title {
  font-weight: 400;
}
.modalFireball {
  margin-bottom: 15px;
}
span.perfectMatch,
span.want,
ul.answered,
span.good {
  color: #4dad45;
  background: none;
}
span.strongMatch {
  color: #82d0ca;
}
span.goodMatch,
span.willing {
  color: #f2f234;
}
span.willing {
  color: #ffa502;
}
span.notMatch,
span.wont,
span.poor {
  color: #ff5938;
  background: none;
}
span.pending,
ul.pending {
  color: white;
}
ul.pending, ul.answered {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 350px;
  flex-flow: column wrap;
}
ul.pending li, ul.answered li {
  margin: 0.8%;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modalBox aside div {
  display: block;
  margin: 7%;
  width: 20%;
  padding: 2%;
  color: rgb(255, 255, 255);
  border-radius: 5%;
}
.want.chart {
  background-color: #4dad45;
}
.will {
  background-color: #ffa502;
}
.wont.chart {
  background-color: #ff5938;
}
.modalBox h3 {
  font-weight: 700;
}
canvas {
  width: 430px !important;
  height: 320px !important;
  margin-right: 20px !important;
}
