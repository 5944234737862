.barbckg {
  display: flex;
  flex-direction: row;
  background-color: rgb(25, 25, 25);
  position: fixed;
  z-index: 2;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 7%;
}

.bar {
  border-radius: 5px;
  max-width: 800px;
  width: 50%;
}
.btn-modal {
  margin-left: 2%;
  border: none;
}
.ant-modal-body p {
  font-weight: 900;
  text-align: center;
}
.button1 {
  width: 100px;
  height: 35px;
  border-radius: 15px 0px 0px 15px;
  background-color: #39c643;
  margin-left: 40%;
  margin-bottom: 4%;
  padding: 1px;
  box-sizing: none;
  outline: none;
}
.ant-modal-title {
  font-weight: 900;
}
.button2 {
  width: 100px;
  height: 35px;
  background-color: #ff9800;
  margin-left: 40%;
  margin-bottom: 4%;
  padding: 1px;
  box-sizing: none;
  outline: none;
}
.button3 {
  width: 100px;
  height: 35px;
  border-radius: 0px 15px 15px 0px;
  background-color: #ff0000;
  margin-left: 40%;
  margin-bottom: 4%;
  padding: 1px;
  box-sizing: none;
  outline: none;
}

@media all and (max-width: 500px) {
  .bar {
    border-radius: 5px;
    background-color: rgb(25, 25, 25);
    z-index: 2;
    position: fixed;
    align-content: center;
    margin-top: 15px;
    max-width: 200px;
    height: 5%;
    width: 100%;
    transform-origin: center center;
  }
  .barbckg {
    display: flex;
    background-color: rgb(25, 25, 25);
    z-index: 1;
    position: fixed;
    max-width: 380px;
    height: 7%;
    width: 100%;
    transform-origin: center center;
  }
  .btn-modal {
    margin-right: -80%;
  }
}
